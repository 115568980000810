
* {
  margin: 0;
  /*border: 0;*/
  box-sizing: border-box;
}

:root {
 /* --font-roboto: "Roboto Slab", serif;
  --font-raleway: "Raleway", sans-serif;*/
}

body {
  font-family: Source Sans Pro, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    display: block;
    margin: 0;
    padding: 5px;
}

.app {
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
}
header {
    width: 100%;
    background-color: #F2F1F1;
    display: block;
    padding-left: 5px;
    margin-bottom: 10px;
    border-bottom: 5px solid #224c60;
}

.app > section {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  margin: auto;
  background-color: #214B60; 
  width: 100%;
  padding: 4rem;
}

.section-title {
  display: flex;
  color: white;
  margin-bottom: 25px;
}

.logo {
    width: 30%;
    max-width: 300px;
}

.search-icons {
  width:4em;
}

.flex-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.flex-container > div {
  display: flex;
  min-height: 150px;
  background-color: white;
  border: 1px solid lightgray;
  justify-content: center;
  flex-grow: 4;
}

.active, .flex-container > div:active {
  background-color: lightgray;
  opacity: .50;
}

.flex-container > div:hover {
  background-color: lightgray;
  opacity: .50;
}

.flex-container > div > p > img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

.search-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

p {
  display: block;
  align-self: center;
}

.search {
  width: auto;
  margin: 1rem 0 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1.5rem 1.75rem;
  border-radius: 3rem;
  background: #f0f0f0;
}

.search input {
  flex: 1;
  border: none;
  font-size: 1.5rem;
  font-family: var(--font-raleway);
  font-weight: 500;
  padding-right: 1rem;

  outline: none;
  color: #1f2123;
  background: #f0f0f0;
}

.search img {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.empty {
  width: 100%;
  margin-top: 3rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

.empty h2 {
  font-size: 1.25rem;
  color: #f9d3b4;
  font-family: var(--font-raleway);
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.info {
  display: flex;
  width: 100%;
}

.provider-card {
  display: flex;
  width: 100%;
  padding:  1rem 1rem .5rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid rgb(211, 211, 211, 0.597);
  position: relative;
  color: #1f2123;
  line-height: 1.5;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.provider-info {
 width: 100%;

}
.provider-facility {
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: .3rem;
}

.location {

}

.location-container {
display: flex;
width: 100%;
flex-direction: row;
justify-content:flex-start;
flex-wrap: wrap;
padding: .50rem .75rem .50rem .75rem;
}

.location > :nth-child(odd) {
  background-color: rgba(237, 237, 237, 0.5);
}

.location-info {
  flex-wrap: wrap;
 
  width: 50%;
  
}

.extended-location-info {
  flex-wrap: wrap;
}

.lable {
  font-weight: 500;
}

.p-name {
  display: flex;
  font-size: 1.15rem;
  font-weight: 700;
  color: #214B60;
  align-items: baseline;
}

.icon-facility::before {
  content: '';
  width: 25px;
  height: 22px;
  margin-right: .5rem;
  display: inline-block;
  background: url("/public/images/building.png") no-repeat 0 0;
  background-size: 100%;
}

.icon-provider::before {
  content: '';
  width: 25px;
  height: 25px;
  margin-right: .5rem;
  display: inline-block;
  background: url("/public/images/doctor.png") no-repeat 0 0;
  background-size: 100%;
}

h4 {
 color: #214B60;
}

.extend-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content:flex-start;
  align-content: space-around;
}

.extend-filters-search {
  margin: 0 1rem 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .75rem 1.75rem;
  border-radius: 3rem;
  background: #f0f0f0;
}

.extend-filters-search input {
  flex: 1;
  border: none;
  font-size: 1.25rem;
  font-family: var(--font-raleway);
  font-weight: 500;
  padding-right: 1rem;
  outline: none;
  color: #1f2123;
  background: #f0f0f0;
}

.extend-filters-search > svg {
  padding-right: .5rem;
  color: #214B60;

}

@media screen and (max-width: 600px) {
  .app {
    padding: 4rem 2rem;
  }

  .search {
    padding: 1rem 1.75rem;
    width: 100%;
  }

  .search input {
    font-size: 1rem;
  }

  .search img {
    width: 20px;
    height: 20px;
  }
  .heading-text {
    margin-top: 50px;
  }
  .logo {
    width: 100%;
    padding: 10px;
  }
}

@media screen and (max-width: 400px) {
  .app {
    padding: 4rem 1rem;
  }
  .logo {
    width: 100%;
    padding: 10px;
}

  h1 {
    font-size: 2rem;
  }

  .container {
    margin-top: 2rem;
  }

  .location-info {
    flex-wrap: wrap;
    margin: 0 1rem 1rem 1rem;
    width: 100%;
  }
  .app > section {
    padding: 1rem;
  }

  .flex-container > div {
    width: 90%;
    min-height: 25px;
    justify-content: flex-start;
    align-items: center;
  }

  .search-selection {
    display: flex;
    align-items: center;
    flex-flow: row;
    align-content: space-around;
    margin-left: .6rem;
    padding: .25rem;
  }

  .search-selection > p {
    margin-left: .5rem;
  }

  .search-icons {
    width: 1.5em;
}

.extend-filters {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.extend-filters-search input {
  font-size: .95rem;
}
}